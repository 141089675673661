<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动区域"
                    prop="list_province">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动类型"
                    class="width300"
                    prop="appointment_type">
        <el-select v-model="form.appointment_type"
                   placeholder="请选择">
          <el-option label="保险业务"
                     value="INSURANCE">
          </el-option>
          <el-option label="有奖测试"
                     value="ANSWER">
          </el-option>
          <el-option label="商户开户"
                     value="MERCHANTS_OPEN">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="显示字段"
                    required>
        <div>总金额：{{totalMoney}}元</div>
        <el-row v-for="(item,index) in money"
                class="mb-15"
                type="flex"
                :key="index">
          <span>金额{{index+1}}：</span>
          <el-input v-model="item.money"
                    type="number"
                    class="mr-15"
                    :min="1"
                    :max="200"
                    style="width:100px"></el-input>
          <span> 数量：</span>
          <el-input v-model.number="item.num"
                    class="mr-15"
                    :min="1"
                    style="width:100px"></el-input>
          <span class="mr-15">个 (小计：{{((item.money*100*item.num)/100).toFixed(2)}}元)</span>
          <el-button size="small"
                     type="primary"
                     v-if="index===0"
                     @click="addMoney()">增加一个</el-button>
          <el-button size="small"
                     type="primary"
                     @click="delMoney(index)">删除</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="标题栏色号"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入标题栏色号"
                  v-model="form.title_color"></el-input>
      </el-form-item>
      <el-form-item label="发放主体简称"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入发放主体简称"
                  v-model="form.subject"></el-input>
      </el-form-item>
      <el-form-item label="每日参与的总次数"
                    prop="participate_num"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入每日参与的总次数"
                  v-model.number="form.participate_num"></el-input>
      </el-form-item>
      <!-- 有奖测试特有 -->
      <template v-if="form.appointment_type==='ANSWER'">
        <el-form-item label="达标题目数"
                      prop="mark_topic_num"
                      class="width300">
          <el-input type="text"
                    placeholder="请输入每日参与的总次数"
                    v-model.number="form.mark_topic_num"></el-input>
        </el-form-item>
        <el-form-item label="达标提示语"
                      prop="mark_topic_msg"
                      class="width300">
          <el-input type="textarea"
                    placeholder="答题达标时显示"
                    v-model="form.mark_topic_msg"></el-input>
        </el-form-item>
        <el-form-item label="达标题目数"
                      prop="return_topic_num"
                      class="width300">
          <el-input type="text"
                    placeholder="请输入重答次数"
                    v-model.number="form.return_topic_num"></el-input>
        </el-form-item>
        <el-form-item label="答题顺序"
                      required>
          <el-radio-group v-model="form.topic_sort">
            <el-radio label="">每次随机顺序</el-radio>
            <el-radio label="desc">按题目排序值</el-radio>
          </el-radio-group>
        </el-form-item>
      </template>

      <!-- 有奖测试特有END -->
      <el-form-item label="封面图片">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="发放主体LOGO">
        <ImgUpload :fileList="imgList2"
                   pixel="用于页面配图，建议200*200像素"
                   v-model="form.logo_img_id" />
      </el-form-item>
      <el-form-item label="背景图">
        <ImgUpload :fileList="imgList3"
                   pixel="用于页面配图，建议640*1100像素"
                   v-model="form.banner_img_id" />
      </el-form-item>
      <el-form-item label="允许参与区域">
        <Address :address.sync="address"
                 :level='2'
                 @change="addressChange" />
      </el-form-item>
      <el-form-item label="日期规则"
                    prop="started_at">
        <WxDate @change="dateChange"
                ref="wxDate"
                :dateRule="dateRule" />
      </el-form-item>
      <el-form-item label="广告语"
                    class="width300">
        <el-input type="textarea"
                  placeholder="请输入广告语"
                  v-model="form.receive_advertising"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setActivity, getActivity } from '@/api/market'
import Address from '@/components/Address'
import ImgUpload from '@/components/ImgUpload'
import WxDate from '@/components/WxDate'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetApponitment',
  data () {
    return {
      dateRule: {},
      imgList: [],
      imgList2: [],
      imgList3: [],
      dateTime: [],
      address: {},
      areaAddress: {},
      form: {
        type: 'APPOINTMENT', // 活动类型
        subject: '', // 主体名称
        title_color: '', // 标题背景颜色
        title: '', // 活动名称
        started_at: '', // 开始时间
        ended_at: '', // 结束时间
        banner_img_id: '', // 背景图片ID
        participate_num: 1, // 每日参与的总次数
        img_id: '', // 封面图片ID
        logo_img_id: '', // 主体图标
        activity_id: '', // 活动id
        money_configs: [], // 奖品数组，不能大于8
        list_province: '', // 活动区域（省份）
        list_city: '', // 活动区域（城市）
        list_district: '', // 活动区域（区）
        list_net: '', // 活动区域（网点）
        appointment_type: 'INSURANCE', // 预约活动的活动类型
        time_rule: 'ANY', // 时间规则
        time_rule_day: [], // 时间规则
        time_rule_time: [], // 时间规则
        time_rule_week: [1, 2, 3, 4, 5, 6, 7], // 时间规则
        receive_advertising: '', // 活动广告语
        allow_province: '', // 限制允许活动的省份
        allow_city: '', // 限制允许活动的城市
        // 有奖测试特有
        mark_topic_num: 1, // 达标题目数
        mark_topic_msg: '', // 达标提示语
        return_topic_num: 1, // 重答次数
        topic_sort: '' // 答题顺序
      },
      rules: {
        subject: [
          { required: true, message: '请输入发放主体简称', trigger: 'blur' }
        ],
        participate_num: [
          { required: true, message: '请输入每日参与的总次数', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请输入上传封面图片', trigger: 'change' }
        ],
        logo_img_id: [
          { required: true, message: '请输入上传发放主体logo', trigger: 'change' }
        ],
        banner_img_id: [
          { required: true, message: '请输入上传背景图', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '选择活动日期', trigger: 'change' }
        ],
        list_province: [
          { required: true, message: '请选择区域，至少为省份', trigger: 'change' }
        ],
        appointment_type: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        mark_topic_num: [
          { required: true, message: '请输入每日参与的总次数', trigger: 'blur' }
        ],
        mark_topic_msg: [
          { required: true, message: '请输入达标提示语', trigger: 'blur' }
        ],
        return_topic_num: [
          { required: true, message: '请输入重答次数', trigger: 'blur' }
        ]

      },
      money: [
        {
          money: 1,
          num: 10
        }
      ]
    }
  },
  computed: {
    totalMoney () {
      let total = 0
      this.money.map(item => {
        total += item.money * 100 * item.num
      })
      return (total / 100).toFixed(2)
    }
  },
  components: {
    Area,
    ImgUpload,
    Address,
    WxDate
  },
  methods: {
    delMoney (index) {
      if (this.money.length > 1) {
        this.money.splice(index, 1)
      } else {
        this.$message.error('显示字段最少为一项')
      }
    },
    addMoney () {
      if (this.money.length < 8) {
        this.money.push({
          money: 1,
          num: 10
        })
      } else {
        this.$message.error('最多支持8个奖品')
      }
    },
    dateChange (e) {
      this.form = Object.assign(this.form, e)
    },
    addressChange (res) {
      this.form.allow_province = res.province
      this.form.allow_city = res.city
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      const wxDateStatus = this.$refs.wxDate.validate()
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!wxDateStatus) {
            return
          }
          this.form.money_configs = JSON.parse(JSON.stringify(this.money))
          this.form.total_money = this.totalMoney * 100
          this.form.money_configs.map(item => {
            item.money = Number((item.money * 100).toFixed(0))
          })
          const res = await setActivity(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('appointments')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity
        activity.activity_id = id
        activity.logo_img_id = getImgId(activity.logo_img)
        activity.logo_img && this.$set(this.imgList2, 0, activity.logo_img)
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList, 0, activity.img_data)
        activity.banner_img_id = getImgId(activity.bg_img)
        activity.bg_img && this.$set(this.imgList3, 0, activity.bg_img)
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.address = {
          province: activity.allow_province,
          city: activity.allow_city,
          district: ''
        }
        this.dateRule = {
          time_rule: activity.time_rule,
          started_at: activity.started_at,
          ended_at: activity.ended_at,
          time_rule_week: activity.time_rule_week,
          time_rule_time: activity.time_rule_time,
          time_rule_day: activity.time_rule_day
        }
        this.money = Object.assign([], activity.money_configs)
        this.money.map(item => {
          item.money = item.money / 100
        })
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
